<template>
  <!-- 首页内容模块 youthf 内容  -->
  <div >
    <section class="captor">
      <div class="container bkgimg">
        <h2 v-if="!isEnglish" class="captor-title">关于我们</h2>
        <h2 v-else class="captor-title">ABOUT US</h2>
        <div v-if="!isEnglish" class="mobile-text">
          <p>弘丰资本是一家面向未来，重点关注高新科技、人工智能和Web3.0领域的产业投资公司。</p>
          <br/>
          <p>弘丰资本以实业为本，凭借十几年来在软件及供应链行业的积累，形成厚实且有支撑的发展底座；以战略为魂，凭借共生共赢，开放共创的企业精神，聚人聚事，对外链接；以金融为器，依托资本和优秀的企业及人才保持沟通，彼此赋能，成就梦想；以科技为魂，相信“科技是第一生产力”，凭借自身及投资企业，打造生态，借此在未来科技圈占有一席之地，为科技及社会进步作出贡献。</p>
          <br/>
          <p>如今，弘丰资本及其关联企业已在新加坡、中国、美国、马来西亚和印度等地设有近20家机构及研发中心。其中，旗下核心公司-弘丰科技及其关联企业先后获得“亚太地区最具创新元宇宙科技公司”等奖项，以专业的团队及过硬的实力获得政府，企业及投资机构的广泛赞誉。</p>
          <br/>
          <p>展望未来，我们相信：凭借我们开放的企业精神和前沿的战略布局，以及专业进取的团队，我们将携手客户，股东及员工一起创造价值，面向未来。</p>
        </div>
        <div v-else class="mobile-text">
          <p>Hongfeng Capital is a future oriented industrial investment company focusing on high-tech, artificial intelligence and Web 3.0. </p>
          <br/>
          <p><span style="font-weight: bold;">Based on Industry: </span>Hongfeng Capital has formed a solid and supportive development base relying on its accumulation in the software and supply chain industry for more than ten years.</p>
          <p><span style="font-weight: bold;">Taking Strategy as the Soul: </span>Relying on the enterprise spirit of symbiosis, win-win, openness and co-creation, we can gather people, project and capital to link with the outside world.</p>
          <p><span style="font-weight: bold;">Taking Finance as the Tool: </span>With the support of capital, communication with excellent enterprises and talents; empower each other and achieve dreams.</p>
          <p><span style="font-weight: bold;">With Science and Technology as the general Trend: </span>As the old saying goes "science and technology are the first productive force", we will build an ecosystem with investment enterprises, so as to occupy a place in the future science and technology circle and make contributions to science and technology and social progress.</p>
          <br/>
          <p>Today, Hongfeng Capital and its affiliated enterprises have set up nearly 20 institutions or R&D centers in Singapore, China, the United States, Malaysia and India.Among them, Hongfeng Technology, our core company, and its affiliated enterprises have successively won awards such as "The Most Innovative Metaverse  Company in Asia Pacific", and won extensive praise from enterprises, investment institutions and governments.</p>
          <br/>
          <p>With our open enterprise spirit, front-end strategic layout, and professional and enterprising team, we will work together with customers, shareholders and employees to create value and face the future.</p>
        </div>
      </div>
    </section>
    <section class="captor">
      <div class="container bkgimg">
        <h2 v-if="!isEnglish" class="captor-title">企业文化</h2>
        <h2 v-else class="captor-title">Enterprise Culture</h2>
        <div class="mobile-text">
          <p v-if="!isEnglish">愿景：丰民立志、弘毅致远</p>
          <p v-else>Vision: Make everyday life more prosperous and inspirating for everyone</p>
          <br />
          <br />
          <p v-if="!isEnglish">使命：让未来触手可及</p>
          <p v-else>Mission: Bring the future within reach</p>
          <br />
          <br />
          <p v-if="!isEnglish">价值观：共识 · 创造 · 分享 · 利他</p>
          <p v-else>Values: Cooperation · Creation · Commitment · Altruism</p>
        </div>
      </div>
    </section>
    <section class="captor">
      <div class="container">
        <h2 v-if="!isEnglish" class="captor-title">投资板块</h2>
        <h2 v-else class="captor-title">Investment sector</h2>
        <div class="mobile-text">
          <p v-if="!isEnglish">物业不动产，供应链相关，电子商务，高新科技</p>
          <p v-else>Real estate , Supply chain, E-Commerce, Technology</p>
          <br />
        </div>
      </div>
    </section>
    <section class="captor">
      <div class="container">
        <h2 v-if="!isEnglish" class="captor-title">关联企业</h2>
        <h2 v-else class="captor-title">Partners & Affiliates</h2>
        <div class="partner-content">
          
          <div class="">
            <div class="imgWrap">
              <img class="favoo" src="../assets/pa_broctagon.png" />
            </div>
          </div>

          <div class="">
            <div class="imgWrap">
              <img class="favoo" src="../assets/pa_logo_vishanti_onblack.png" />
            </div>
          </div>

          <div class="">
            <div class="imgWrap">
              <img class="favoo" src="../assets/pa_logo_affinityanalyst_onblack.png" />
            </div>
          </div>

          <div class="">
            <div class="imgWrap">
              <img class="favoo" src="../assets/pa_logo_ai2labs_bright.png" />
            </div>
          </div>

          <div class="">
            <div class="imgWrap">
              <img class="favoo" src="../assets/pa_logo_linkvc_bright.jpg" />
            </div>
          </div>

          <div class="">
            <div class="imgWrap">
              <img class="favoo" style="padding-bottom: 5%;" src="../assets/pa_logo_myrlabs_dark.svg" />
            </div>
          </div>

          <div class="">
            <div class="imgWrap">
              <img class="favoo" src="../assets/pa_logo_silversea_br_onwhite.png" />
            </div>
          </div>

          <div class="">
            <div class="imgWrap">
              <img class="favoo" src="../assets/pa_glink.png" />
            </div>
          </div>

          <div class="">
            <div class="imgWrap">
              <img class="favoo" src="../assets/logo_technology.png">
            </div>
          </div>
         
          <div class="">
            <div class="imgWrap">
              <img class="favoo" src="../assets/DreamHeaven.png" />
            </div>
          </div>


        </div>
        <!-- <div class="partner-content">
          <div class="">
            <div class="imgWrap">
              <img class="favoo" src="../assets/oldamerican_legend.png">
            </div>
            <p v-if="!isEnglish">老美往事 OLDAMERICAN  LEGEND</p>
            <p v-else>OLDAMERICAN  LEGEND</p>
          </div>
          <div class="">
            <div class="imgWrap">
              <img class="favoo" src="../assets/BBB.png">
            </div>
            <p v-if="!isEnglish">BBB德国精酿餐厅 </p>
            <p v-else>Bavarian Brew Restaurant</p>
          </div>
          <div class="">
            <div class="imgWrap">
              <img class="favoo" src="../assets/xiamenshilijia.png">
            </div>
            <p v-if="!isEnglish">厦门食力家食品科技有限公司</p>
            <p v-else>XiaMen ShiLiJia Food Technology Co., LTD</p>
          </div>
          <div class="">
            <div class="imgWrap">
              <img class="favoo" src="../assets/DreamHeaven.png" />
            </div>
            <p v-if="!isEnglish">厦门梦天堂网络技术有限公司</p>
            <p v-else>Dream Heaven Co.，LTD</p>
          </div>
        </div> -->
      </div>
    </section>
  </div>
</template>

<script>
import isEnglish from './isEnglish.js'
export default {
  name: "LinkvcContent",
    mixins: [isEnglish]
}
</script>
<style scoped>
.bkgimg {
  background-image: url(../assets/aboutbg.png);
  background-position: right center;
  background-repeat: no-repeat;
}
.captor {
    padding-bottom: 100px;
    padding-top: 100px;
}

.container {
  width: 90%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  text-align: left;
}
.captor-title {
  font-size: 28px;
}
.captor-title:after {
    margin-top: 20px;
    content: "";
    display: block;
    width: 32px;
    height: 6px;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(68,129,235,1) 0%, rgba(4,190,254,1) 100%);
    box-shadow: 0 15px 25px rgb(68 125 235 / 60%);
}

.mobile-text {
    padding-top: 40px;
    line-height: 1.6;
    width: 50%;
    font-weight: 200;
}


.partner-content {
    padding-top: 60px;
    display: flex;
    flex-wrap: wrap;
}

.partner-content >  div {
    box-sizing: border-box;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: space-between; */
    height: 220px;
    width: 20%;
}

.partner-content p {
  text-align: center;
}

.imgWrap {
  height: 200px;
  display: flex;
}
.partner-content img {
    display: block;
    width: 170px;
    margin:  auto;
}
@media (max-width: 600px) {
.captor {
    padding-top: 60px;
    padding-bottom: 60px;
}

.captor-title {
    font-size: 20px;
}

.mobile-text {
    width: 90%;
}

.partner-content {
    padding-top: 60px;
}


.partner-content > div {
  width: 50%;
  height: 180px;
}

.partner-content  p {
  font-size: 12px;
}

.partner-content .imgWrap {
  text-align: center;
  height: 130px!important;
}

.partner-content img {
    max-width: 80px;
    height: auto;
}

}

</style>