<template>
  <div id="app">
    <Home />
    <div class= "ba" @click="clickBa">闽ICP备 2021003391号</div>
  </div>
</template>

<script>
import Home from './components/Home.vue'

export default {
  name: 'App',
  components: {
    Home
  },
  methods: {
    clickBa() {
      window.open("https://beian.miit.gov.cn/")
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

body {
  display: block;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}


.dot:before {
  content: "|";
  margin-left: 20px;
  margin-right: 20px;
}

.ba {
  color:#939393;
  text-decoration:none;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
}
</style>
