import { render, staticRenderFns } from "./LinkvcContent.vue?vue&type=template&id=274d1175&scoped=true&"
import script from "./LinkvcContent.vue?vue&type=script&lang=js&"
export * from "./LinkvcContent.vue?vue&type=script&lang=js&"
import style0 from "./LinkvcContent.vue?vue&type=style&index=0&id=274d1175&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274d1175",
  null
  
)

export default component.exports