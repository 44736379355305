<template>
    <!-- 地图  -->
   <div id="gd-map">
     <!-- 技术支持和联系方式  -->
   </div>
 </template>
 
 <script>
 import AMapLoader from "@amap/amap-jsapi-loader";
 // 设置安全密钥
 window._AMapSecurityConfig = {
     securityJsCode: 'd61e49a6cb4cc5697377b1aad8a736b2',
 }

 export default {
     data() {
         return {
             map : null,
             mouseTool : null,
             overlays : [],
             auto : null,
             placeSearch : null, 
         }
     },
     mounted() {
        this.initMap();
    },
     methods : {
         initMap() {
             AMapLoader.load({
                 "key": "eb3dbb8cbd53594fa7a0bade8ceeac31", 
                 "version": "1.4.15",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                 "plugins": [],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
             }).then((AMap)=>{
                console.log("初始化地图")
                 // 初始化地图
                 var x = 20.405467;
                 var y = 15.907761;
                 if(IsPhone()){
                    x = 108.391467;
                    y = 3.907761;
                 }
                 console.log(IsPhone())
                 this.map = new AMap.Map('gd-map',{
                     viewMode : "2D",  //  是否为3D地图模式
                     zoom : 1,   // 初始化地图级别
                     zooms:[1,8],
                     lang: "zh_en",
                     center : [x, y], //中心点坐标
                    //  zoomEnable:false,
                    //  dragEnable: false,
                     resizeEnable: true,
                     features: ['bg'],
                 });

                 var marker1 = new AMap.Marker({
                    map: this.map,
                    title: "中国",
                    content: "",
                    offset: new AMap.Pixel(-40, -40),
                    extData:{id:"China"}
                });
                var marker3 = new AMap.Marker({
                    map: this.map,
                    extData:{id:"U.S.A"}
                });
                var marker4 = new AMap.Marker({
                    map: this.map,
                    offset: new AMap.Pixel(-20, -55),
                    extData:{id:"Malaysia"}
                });
                var marker2 = new AMap.Marker({
                    map: this.map,
                    title: "新加坡",
                    offset: new AMap.Pixel(-25, -65),
                    extData:{id:"Singapore"}
                });
                var marker5 = new AMap.Marker({
                    map: this.map,
                    extData:{id:"India"}
                });

                setMarker(marker1, "China", "China-中国", [116.391467, 39.927761]);
                setMarker(marker4, "Malaysia", "Malaysia-马来西亚", [101.722382, 3.112143]);
                setMarker(marker3, "U.S.A", "U.S.A-美国", [-80.02182, 38.95707]);
                setMarker(marker5, "India", "India-印度", [77.152382, 28.472143]);
                setMarker(marker2, "Singapore", "Singapore-新加坡", [103.805467, 1.357761]);

                function setMarker(marker, country, innerHTML, position){
                    // 自定义点标记内容
                    var markerContent = document.createElement(country);
                    // 点标记中的图标
                    var markerImg = document.createElement("img");
                    markerImg.className = "markerlnglat";
                    markerImg.src = "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png";
                    markerContent.appendChild(markerImg);
                    var markerSpan = document.createElement("span");
                    markerSpan.className = 'marker';
                    markerSpan.innerHTML = innerHTML;
                    markerContent.appendChild(markerSpan);
                    marker.setContent(markerContent); //更新点标记内容
                    marker.setPosition(position); //更新点标记位置
                }
                function IsPhone() {
                    //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
                    var info = navigator.userAgent;
                    //通过正则表达式的test方法判断是否包含“Mobile”字符串
                    var isPhone = /mobile/i.test(info);
                    //如果包含“Mobile”（是手机设备）则返回true
                    return isPhone;
                }

             }).catch(e => {
                 console.log(e);
             });
         }
         
    }
    
 };

        
 
 </script>
 
 <style scoped>
 #gd-map {
     overflow: hidden;
     width: 100%;
     height: 500px;
     margin: 0;
     font-family: "微软雅黑";
 }
 </style>
 <style>
 /* 隐藏高德logo  */
 .amap-logo {
     display: none !important;
 }
 /* 隐藏高德版权  */
 .amap-copyright {
     display: none !important;
 }
 .marker {
            /* position: absolute; */
            /* top: -20px; */
            /* right: -118px; */
            color: #fff;
            padding: 4px 10px;
            box-shadow: 1px 1px 1px rgba(10, 10, 10, .2);
            white-space: nowrap;
            font-size: 12px;
            font-family: "";
            background-color: #25A5F7;
            border-radius: 3px;
        }
 </style>
