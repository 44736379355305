<template>
  <div>
      <HeaderName @changeEnglish="changeEnglish" :isEnglish="isEnglish" />
      <ContentName @changeEnglish="changeEnglish" :isEnglish="isEnglish" />
      <FooterName @changeEnglish="changeEnglish" :isEnglish="isEnglish" />
  </div>
</template>

<script>
import HeaderName from './Header.vue';
import ContentName from './LinkvcContent.vue';
import FooterName from './FooterLinkvc.vue';

export default {
  name: 'HelloWorld',
  components: {
    HeaderName,
    ContentName,
    FooterName
  },
  data() {
    return {
      isEnglish: true
    }
  },
  methods: {
    changeEnglish(isEnglish) {
      this.isEnglish = isEnglish;
      if(isEnglish) {
        document.title = '弘丰资本|HongFeng Capital|H.F Capital - 让未来触手可及|弘丰投资|HongFeng Investment|H.F Investment|弘丰科技|HongFeng Technology|H.F Technology - Bring the future within reach'
      } else {
        document.title = '弘丰资本|HongFeng Capital|H.F Capital - 让未来触手可及|弘丰投资|HongFeng Investment|H.F Investment|弘丰科技|HongFeng Technology|H.F Technology - Bring the future within reach'
      }
    }
  }
}
</script>


<style scoped>

</style>
